import React from "react";

export const FormGroup = function ({type, name, active, setActive}) {

    return (
        <div className={"form__group " + (active === name ? "focus" : null)}>
            <label htmlFor={name}>{name}</label>
            {type === "textarea"
                ? <textarea onFocus={() => setActive(name)} name={name} id={name} rows="4" />
                : <input onFocus={() => setActive(name)} type={type} name={name} id={name} />
            }
        </div>
    )
}

export default FormGroup