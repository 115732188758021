import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"
import { MENU } from "../../config"

// Assets
import facebook from "../../images/icons/facebook.svg"
import instagram from "../../images/icons/instagram.svg"



const Header = ({ siteTitle }) => {


    const [menuIsOpen, setMenuIsOpen] = useState(false)

    function clickHandlerButton() {
        setMenuIsOpen(!menuIsOpen)
    }

    function handleClickOnMenuLinks(event) {

        event.preventDefault()
        const to = event.currentTarget.href
        setMenuIsOpen(false)
        document.body.classList.add("loaded")
        if( event.currentTarget.innerHTML === "Contact") {
            document.body.style.backgroundColor = "#fefefe"
            document.body.classList.add("animatedBG")
        }
        setTimeout(() => {
            window.location.replace(to);
        }, 800)
    }

    const m = Object.keys(MENU).map((item, index) => {
        // console.log(MENU[item].name)
        return <li key={index} className={"nav__item"}> <Link onClick={handleClickOnMenuLinks}  data-description={MENU[item].description} to={MENU[item].link}>{MENU[item].name}</Link> </li>
    })


    return (
        <header className={"site-header nav"}>
            <div className={"nav__top"}>
                <h1 className={"title title--logo"}>
                    <Link to={MENU.home.link} >
                        {siteTitle}
                    </Link>
                </h1>

                <button className={"nav__toggler btn"} onClick={clickHandlerButton}>
                    <svg className={"nav__hamburger btn__icon "  + (menuIsOpen ? "open" : null)} viewBox="0 0 7 7">
                        <rect className="line-1" y="0" width="7" height="1"></rect>
                        <rect className="line-2" y="3" width="7" height="1"></rect>
                        <rect className="line-3" y="6" width="7" height="1"></rect>
                    </svg>
                    <span className="btn__label">
                        {(menuIsOpen ? "Close" : "Menu")}
                    </span>
                </button>
            </div>
            <div className={"nav__content " + (menuIsOpen ? "open" : null)}>
                <nav className={"nav__menu"}>
                    <ul>
                        { m }
                    </ul>
                </nav>

                <div className="nav__contact contact--encart">
                    <p className={"title title--logo"}>
                        <Link to={MENU.home.link} >
                            {siteTitle}
                        </Link>
                    </p>
                    <p>
                        A digital team who build your brand with you!
                    </p>
                    <div className={"contact__links"}>
                        <span className={"bold"}>Tel:</span><a href="tel:+32485590218"> +32 485 59 02 18</a><br/>
                        <a href="mailto:info@predicat.be">info@predicat.be</a>
                    </div>
                    <div>
                        <a className={"links"} target="_blank" rel="noreferrer" href="https://www.facebook.com/predicat.be/"><img className={"links__icon"} src={facebook} alt="facebook"/>Facebook</a>
                        <a className={"links"} target="_blank" rel="noreferrer" href="https://www.instagram.com/predicat.be/"><img className={"links__icon"} src={instagram} alt="Instagram"/>Instagram</a>
                    </div>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
