import React, {useEffect, useState} from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import FormGroup from "../components/form/FormGroup";
import {Link} from "gatsby";
import facebook from "../images/icons/facebook.svg";
import instagram from "../images/icons/instagram.svg";



const IndexPage = () => {
    const [activeEl, setActiveEl] = useState('');
    const [mailSend, setMailSend] = useState(false);

    useEffect(() => {
        document.body.classList.add('light')
        document.body.classList.add('full')
        document.body.removeAttribute('style')

        return function () {
            document.body.classList.remove('light')
            document.body.classList.remove('full')
        }
    }, [])

    function handleFormSubmit(e) {
        e.preventDefault()
        const formData = new FormData(document.querySelector(".contact-form"))

        fetch('https://predicat.be/api/sendEmail.php', {
            method:"POST",
            body: formData,
        }).then(response => {
            if(response.status !== 200) {
                return
            }
            setMailSend(true)
        })
    }

    return (
        <Layout>
            <SEO title="Contact" />

            <section className="container contact__container">
                <h1><span role="img" aria-label={"hello"} className={"hello"}> 👋</span><br />Hello, let's get in touch</h1>
                <div className="card">
                    <div className="contact__info">
                        <div>
                            <p className={"title title--logo"}>
                                <Link to="/">Predicat</Link>
                            </p>
                            <p>
                                A digital team who build your brand with you!
                            </p>
                        </div>
                        <div>
                            <div className={"contact__links"}>
                                <span className={"bold"}>Tel:</span><a href="tel:+32485590218"> +32 485 59 02 18</a><br/>
                                <a href="mailto:info@predicat.be">info@predicat.be</a>
                            </div>
                            <div>
                                <a className={"links"} target="_blank" rel="noreferrer" href="https://www.facebook.com/predicat.be/"><img className={"links__icon"} src={facebook} alt="facebook"/>Facebook</a>
                                <a className={"links"} target="_blank" rel="noreferrer" href="https://www.instagram.com/predicat.be/"><img className={"links__icon"} src={instagram} alt="Instagram"/>Instagram</a>
                            </div>
                        </div>
                    </div>
                    {mailSend
                        ? <p className={"contact__message"}>Mail Envoyé</p>
                        :<form className={"contact-form"} onSubmit={handleFormSubmit} method="POST">
                            <FormGroup name={'name'} active={activeEl} setActive={setActiveEl} type={'text'}/>
                            <FormGroup name={'mail'} active={activeEl} setActive={setActiveEl} type={'Email'}/>
                            <FormGroup name={'subject'} active={activeEl} setActive={setActiveEl} type={'text'}/>
                            <FormGroup name={'message'} active={activeEl} setActive={setActiveEl} type={'textarea'}/>
                            <div className="row form__row">
                                <button className={"btn btn--form"} type="submit">Send message</button>
                            </div>
                        </form>
                    }
                </div>
            </section>
        </Layout>
    )
}

export default IndexPage
